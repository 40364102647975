import Link from 'next/link';
import { PropsWithChildren } from 'react';

import { Colors } from '@/styles/colors';
import { FontSize } from '@/styles/tokens';

import * as styles from './AnchorLink.styles';

interface AnchorLinkProps {
  url?: string;
  color: Colors;
  size?: FontSize;
  target?: '_blank';
  onClick?(): void;
}

export const AnchorLink = ({
  url = '#',
  size = FontSize.S14,
  color,
  children,
  target,
  ...passedProps
}: PropsWithChildren<AnchorLinkProps>) => (
  <Link
    href={url}
    passHref
    rel="noopener"
    target={target}
    className={styles.anchorLink(color, size)}
    {...passedProps}
  >
    {children}
  </Link>
);
