import { Colors } from '@/styles/colors';
import { Spacings } from '@/styles/spacings';
import { FontSize } from '@/styles/tokens';
import { css } from '@emotion/css';

export const anchorLink = (color: Colors, fontSize?: FontSize) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    color,
    fontSize,
    svg: {
      marginRight: Spacings.S8,
      marginLeft: 2,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  });
