export const useLocalStorage = () => {
  if (typeof window === 'undefined') {
    return {
      setValue: (key: string, value: string) => {},
      getValue: (key: string) => '',
      removeValue: (key: string) => {},
    };
  }

  const setValue = (key: string, value: string) =>
    localStorage.setItem(key, value);

  const getValue = (key: string) => localStorage.getItem(key);

  const removeValue = (key: string) => localStorage.removeItem(key);

  return { setValue, getValue, removeValue };
};
