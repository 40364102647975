import { AnchorLink } from '@/components/AnchorLink/AnchorLink';
import { Typography } from '@/components/Typography/Typography';
import { Colors } from '@/styles/colors';
import { FontSize, FontWeight } from '@/styles/tokens';
import * as styles from './AnchorList.styles';

export enum ListOrientation {
  vertical,
  horizontal,
}

interface LinkListProps {
  title?: string;
  orientation?: ListOrientation;
  anchors: Array<{
    name: string;
    href: string;
    icon?: React.ReactElement;
  }>;
}

export const AnchorList = ({
  orientation = ListOrientation.vertical,
  anchors,
  title,
}: LinkListProps) => (
  <div>
    {title && (
      <Typography
        Element={'p'}
        fontSize={FontSize.S16}
        fontWeight={FontWeight.S500}
        color={Colors.gray400}
      >
        {title}
      </Typography>
    )}
    <ul className={styles.list(orientation)}>
      {anchors.map((anchor) => (
        <li key={anchor.name}>
          <AnchorLink
            size={FontSize.S16}
            target={'_blank'}
            color={Colors.gray600}
            url={anchor.href}
          >
            {anchor.icon} {anchor.name}
          </AnchorLink>
        </li>
      ))}
    </ul>
  </div>
);
