import { MediaQuery, mq } from '@/styles/mediaQueries';
import { Spacings } from '@/styles/spacings';
import { css } from '@emotion/css';

export const headerWrapper = css({
  display: 'flex',
  overflow: 'hidden',
  justifyContent: 'space-between',
});

export const headerNav = css({
  display: 'flex',
  alignItems: 'center',
  '> *': {
    marginLeft: Spacings.S12,
  },
});

export const pagesNavList = css({
  display: 'flex',
  listStyle: 'none',
  marginLeft: Spacings.S42,
  li: {
    marginRight: Spacings.S32,
  },
  [mq(MediaQuery.Mobile)]: {
    display: 'none',
  },
});

export const headerNavLinks = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: Spacings.S16,
  '> *': {
    marginLeft: Spacings.S32,
  },
});

export const headerPagesNav = css({
  display: 'flex',
  alignItems: 'center',
  svg: {
    cursor: 'pointer',
  },
});

export const header = css({
  background: 'rgb(14, 17, 22)',
  padding: `${Spacings.S22} 0`,
  zIndex: 99,
  position: 'sticky',
  left: 0,
  right: 0,
  top: 0,
  transition: 'background-color 0.3s ease',
  'a, p, li': {
    color: '#E4E4E7',
  },
});

export const headerScrolled = css({
  background: '#fff',
  // borderBottom: `1px solid ${Colors.gray200}`,
  'a, p, li': {
    color: 'rgb(14, 17, 22)',
  },
});
