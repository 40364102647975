import { AnchorLink } from '@/components/AnchorLink/AnchorLink';
import { Button, ButtonSize } from '@/components/Button/Button';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { Colors } from '@/styles/colors';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import * as styles from './CookieInfo.styles';

interface CookieInfoProps {
  cookiesTokenKey: string;
}

export const CookieInfo = (props: CookieInfoProps) => {
  const [isConsentAgreed, setAgreeConsent] = useState(true);
  const { getValue, setValue } = useLocalStorage();
  const { t } = useTranslation(I18nNamespaces.Homepage);

  useEffect(() => {
    const display = getValue(props.cookiesTokenKey);
    setAgreeConsent(!!display);
  }, [props.cookiesTokenKey]);

  const handleAcceptCookies = () => {
    setValue(props.cookiesTokenKey, '1');
    setAgreeConsent(true);
  };

  return !isConsentAgreed ? (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p>{t('cookieinfo.header')}</p>
        <Button onClick={handleAcceptCookies} size={ButtonSize.small}>
          {t('cookieinfo.acceptbutton')}
        </Button>
      </div>
      <p className={styles.text}>
        {t('cookieinfo.text')}{' '}
        <AnchorLink
          color={Colors.orange600}
          url={'/docs/privacy-policy.pdf'}
        >
          {t('cookieinfo.privacypolicylink')}
        </AnchorLink>
      </p>
    </div>
  ) : null;
};
