import { AnchorLink } from '@/components/AnchorLink/AnchorLink';
import { Colors } from '@/styles/colors';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

interface AnchorActiveLinkProps {
  href: string;
  hoverColor?: Colors;
}
export const AnchorActiveLink = (
  props: PropsWithChildren<AnchorActiveLinkProps>
) => {
  const router = useRouter();

  return (
    <AnchorLink
      color={
        router.pathname === props.href
          ? props.hoverColor || Colors.orange500
          : Colors.gray800
      }
      url={props.href}
    >
      {props.children}
    </AnchorLink>
  );
};
