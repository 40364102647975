import { IconDefault } from '@/components/Icons/types';

export const MobileMenuBurger = (props: IconDefault) => (
  <svg
    width="34"
    height="34"
    onClick={props.onClick}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 12.4167H25.5"
      stroke="#EA580C"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8.5 22.5832H25.5"
      stroke="#EA580C"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const MobileMenuClose = (props: IconDefault) => (
  <svg
    width="34"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    onClick={props.onClick}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.69636 0.294358C2.30583 -0.0961659 1.67267 -0.0961659 1.28214 0.294358C0.891619 0.684883 0.891619 1.31805 1.28214 1.70857L6.58578 7.01221L1.28229 12.3157C0.89177 12.7062 0.89177 13.3394 1.28229 13.7299C1.67282 14.1204 2.30598 14.1204 2.69651 13.7299L7.99999 8.42642L13.303 13.7294C13.6935 14.1199 14.3266 14.1199 14.7172 13.7294C15.1077 13.3389 15.1077 12.7057 14.7172 12.3152L9.41421 7.01221L14.7173 1.70909C15.1078 1.31857 15.1078 0.685401 14.7173 0.294877C14.3268 -0.0956476 13.6936 -0.0956472 13.3031 0.294877L7.99999 5.59799L2.69636 0.294358Z"
      fill="#EA580C"
    />
  </svg>
);
