import { Colors } from '@/styles/colors';
import { MediaQuery, mq } from '@/styles/mediaQueries';
import { Spacings } from '@/styles/spacings';
import { FontLineHeight, FontSize } from '@/styles/tokens';
import { css } from '@emotion/css';

export const footer = css({
  // minHeight: 432,
  background: Colors.white,
  padding: 76,
  [mq(MediaQuery.Mobile)]: {
    padding: `${Spacings.S36} ${Spacings.S12}`,
  },
});

export const footerLinkListsLayout = css({
  display: 'grid',
  gridTemplateColumns: '392px 1fr 1fr 1fr',
  [mq(MediaQuery.Mobile)]: {
    gridTemplateColumns: '1fr',
    rowGap: 40,
  },
});

export const copyrightWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: 0,
  paddingTop: 130,
  [mq(MediaQuery.Mobile)]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingTop: 44,
  },
});

export const socialIcons = css({
  display: 'flex',
  alignItems: 'center',
  listStyle: 'none',
  paddingLeft: 120,
  li: {
    marginRight: Spacings.S24,
    svg: {
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.7,
      },
    },
  },
  [mq(MediaQuery.Mobile)]: {
    paddingLeft: 0,
  },
});

export const copyright = css({
  fontSize: FontSize.S16,
  color: Colors.gray400,
  paddingTop: 22,
  lineHeight: FontLineHeight.H21,
  [mq(MediaQuery.Mobile)]: {
    fontSize: FontSize.S12,
    paddingTop: 22,
  },
});
