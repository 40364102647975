import { ListOrientation } from '@/components/AnchorList/AnchorList';
import { Colors } from '@/styles/colors';
import { MediaQuery, mq } from '@/styles/mediaQueries';
import { Spacings } from '@/styles/spacings';
import { FontSize } from '@/styles/tokens';
import { css } from '@emotion/css';

export const list = (orientation?: ListOrientation) =>
  css({
    marginTop: 20,
    listStyle: 'none',
    flexWrap: 'wrap',
    display:
      orientation === ListOrientation.horizontal ? 'flex' : 'block',
    li: {
      margin: `20px ${Spacings.S32} 0 0`,
    },

    [mq(MediaQuery.TabletVertical)]: {
      flexDirection: 'column',
    },
    [mq(MediaQuery.TabletVertical)]: {
      li: {
        margin: `${Spacings.S16} ${Spacings.S32} 0 0`,
      },
    },
  });

export const link = css({
  fontSize: FontSize.S18,
  color: Colors.gray800,
});
