import { Colors } from '@/styles/colors';
import { MediaQuery, mq } from '@/styles/mediaQueries';
import { Spacings } from '@/styles/spacings';
import { BorderRadius, FontSize, FontWeight } from '@/styles/tokens';
import { css } from '@emotion/css';

export const wrapper = css({
  position: 'fixed',
  bottom: 27,
  right: 50,
  width: 418,
  minHeight: 154,
  zIndex: 99,
  padding: 20,
  background: Colors.white,
  boxShadow:
    '0px 0px 19.5211px rgba(0, 0, 0, 0.1), 0px 0px 14px rgba(0, 0, 0, 0.04)',
  borderRadius: BorderRadius.S4,
  [mq(MediaQuery.Mobile)]: {
    top: 78,
    right: 0,
    left: 0,
    width: '100%',
    minHeight: 'unset',
    bottom: 'unset',
  },
});

export const header = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: Spacings.S16,
  p: {
    fontSize: FontSize.S16,
    color: Colors.gray700,
    fontWeight: FontWeight.S700,
  },
});

export const text = css({
  fontSize: FontSize.S14,
  color: Colors.gray500,
});
